import React from 'react';
import "./Login.scss";
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Banner from "../../assets/banner.jpg";
export default function Login() {
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = (values) => {
    if (values.username === "admin@bkakgassociation.com" & values.password === "bkakg@123") {
      window.location.replace('https://online.bkakgassociation.com/');
    } else {
      messageApi.open({
        type: 'error',
        content: 'Invalid User Name or Password. Try Again',
      });
    }
  };
  return (
    <div className="login_container">
      {contextHolder}
      <div className="login-form">
        <img src={Banner} className="banner_logo" alt="logo" />
        <p className='header_text'>Login Your Account Here!</p>
        <Form name="normal_login" initialValues={{ remember: true, }} onFinish={onFinish}>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!', },]}>
            <Input className='input_text' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!', },]}         >
            <Input className='input_text' prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button shape="round" className='loginButton button_text' htmlType="submit">Login</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
