import React from 'react';
import "./Home.scss";
import Banner from "../../assets/banner.jpg";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
export default function Home() {
    const navigate = useNavigate();
    return (
        <div className="app_container">
            <img src={Banner} className="banner_logo" alt="logo" />
            <p className='header_text'>BKAKG ASSOCIATION - BHAVANI</p>
            <div className='singleView'>
                <Button shape="round" className='loginButton button_text' onClick={()=>navigate(`/login`) }>Login</Button>
                <Button shape="round" className='downloadButton button_text'>Download Android App</Button>
            </div>
        </div>
    )
}